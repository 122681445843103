@font-face {
  font-family: "Cocogoose Pro Thin";
  src: url(assets/fonts/CocogooseThin.ttf) format("opentype");
}

@font-face {
  font-family: "Cocogoose Pro";
  src: url(assets/fonts/CocogooseLight.ttf) format("opentype");
}

@font-face {
  font-family: "Pistilli";
  src: url(assets/fonts/Pistilli-Roman.woff2) format("woff2");
}

@font-face {
  font-family: "moderline";
  src: local("moderline"), url(./assets/fonts/modernline.woff) format("woff");
}


/*Color palette of the project*/

.darkwhitecolor {
  color: #f1f1f1;
}

.graycolor {
  background-color: #f8f8f8;
}

.darkgraycolor {
  background-color: #333333;
}

.rosecolor {
  background-color: #b1a0a2;
}


/*New fonts*/

.poppins{
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.poppinsbold{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.poppinsextrabold{
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.bebas {
  font-family: 'Bebas Neue', cursive;
  letter-spacing: 0.5px;
}

/*All text and title styles*/

.coco {
  font-family: "Cocogoose Pro Thin";
  font-weight: bolder;
}

.modernline {
  font-family: 'moderline';

}

.roboto {
  font-family: 'Roboto', sans-serif;
  line-height: 0.8;
  font-weight: 500;
}


/*style text color*/

.textblack {
  color: #333333;
}

.textwhite {
  color: white;
}

/*style size title*/

.frasesize {
  font-size: 35pt;
}


.hugetitle {
  font-size: 32pt;
}

.bigtitle {
  font-size: 25pt;
}

.rosetitle {
  font-size: 42pt;
}

.articletitle {
  font-size: 25pt;
}

.show-articletitle {
  font-size: 180%;
  line-height: 1.1;
}

/*style for long texts*/


.hugetext {
  font-size: 140%;
}

.bigtext {
  font-size: 100%;
}

.regulartext {
  font-size: 100%;
  letter-spacing: 1px;
}

.smalltext {
  font-size: 80%;
  letter-spacing: 1px;
}

.menutext {
  font-size: 106%;
}

.extrasmalltext {
  font-size: 30%;
  letter-spacing: 1px;
}


.bigtext:hover {
  font-size: 100%;
}

.regulartext:hover {
  font-size: 100%;
  letter-spacing: 1px;
}

.smalltext:hover {
  font-size: 80%;
  letter-spacing: 1px;
}

.menutext:hover {
  font-size: 105%;
}

/*Estilos de portada*/

.rosecover {
  height: 29vh;
  background-color: #b1a0a2;
}

.show-mobile {
  display: none;
}

.nav-ipad-mobile {
  display: none;
}

.none-desktop {
  display: none;
}

.none-ipad {

}


@media screen and (max-width: 1024px) {
  .rosecover {
    height: 15vh;
    background-color: #b1a0a2;
  }

  .menutext {
    font-size: 80%;
  }

  .rosetitle {
    font-size: 25pt;
  }

  .frasesize {
    font-size: 230%;
  }

  .hugetext {
    font-size: 110%;
  }

  .hugetitle {
    font-size: 220%;
  }

  .show-articletitle {
    font-size: 190%;
    line-height: 1.1;
  }

}


@media screen and (max-width: 780px) {
  .rosecover {
    height: 15vh;
    background-color: #b1a0a2;
  }

  .none-ipad {
    display: none;
  }
  .rosetitle {
    font-size: 25pt;
  }

  .frasesize {
    font-size: 220%;
  }


  .nav-ipad-mobile {
    display: block;
  }

  .hugetitle {
    font-size: 20pt;
  }


  .smalltext {
    font-size: 70%;
    letter-spacing: 1px;
  }

  .bigtext {
    line-height: normal;
  }

  .articletitle {
    font-size: 180%;
    line-height: 1.1;
  }

  .show-articletitle {
    font-size: 180%;
    line-height: 1.1;
  }

  .hugetext {
    font-size: 140%;
  }
}


@media screen and (max-width: 600px) {

  .rosecover {
    height: 17vh;
    background-color: #b1a0a2;
  }

  /*No mostrar en celulares */
  .none-mobile {
    display: none;
  }

  .none-desktop {
    display: block;
  }

  .show-mobile {
    display: block;
  }

  .nav-ipad-mobile {
    display: block;
  }

  /*style size title*/
  .frasesize {
    font-size: 165%;
  }

  .rosetitle {
    font-size: 23pt;
    line-height: 0.8;
  }

  .hugetitle {
    font-size: 200%;
  }

  .bigtitle {
    font-size: 165%;
  }

  .hugetext {
    font-size: 110%;
  }

  .bigtext {
    font-size: 115%;
  }

  .bigtext:hover {
    font-size: 115%;
  }

  .regulartext {
    font-size: 105%;
    letter-spacing: 1px;
  }

  .regulartext:hover {
    font-size: 105%;
    letter-spacing: 1px;
  }

  .articletitle {
    font-size: 120%;
    line-height: 1.1;
  }

  .show-articletitle {
    font-size: 130%;
    line-height: 1.1;
  }
}


html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
